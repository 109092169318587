* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', Arial, sans-serif !important;
  text-decoration: none;
  user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  margin: 0;
  padding: 0;
}

.hidden {
  display: none;
}

a {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

a:hover,
a:visited {
  color: white !important;
  text-decoration: none !important;
}

.portada {
  display: block;
  margin: 0 auto;
  width: 100%;
}

footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 160px;
  background-color: #384d56;
  color: white;
  justify-content: center;
  align-items: center;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 30px;
  background-color: #384d56;
  color: white;
  z-index: 1;
}

input {
  display: block;
  border: none;
  border-bottom: solid rgb(143, 143, 143) 1px;
  background: none;
  color: black;
  font-weight: 700;
  font-size: 20px;
  height: 35px;
  width: calc(100% - 2px);
  margin-bottom: 5px;
}

input:focus-visible {
  outline: none;
}

input::placeholder {
  font-family: roboto, sans-serif;
  font-weight: 300;
  font-size: 12px;
  font-style: italic;
}

@media screen and (min-width: 768px) {
  .portada {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
}
